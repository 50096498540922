import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpRequest, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  token = 'Bearer ' + localStorage.getItem('token');
  private auth: AuthService;

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem('token');
    const refresh: string = localStorage.getItem('refresh');

    let request = req;

    if (token) {
      request = req.clone({
        setHeaders: {
          authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          if(this.auth)
          if (this.auth.getEstadoRefrescando() == false) {
            this.auth.setEstadoRefrescando(true);
              this.auth.refresh().subscribe(resp => {
                this.auth.setEstadoRefrescando(false);
                window.location.reload();
              });
          }
        }

        return throwError(err);
      })
    );
  }
}