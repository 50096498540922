import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map} from 'rxjs/operators';
import decode from 'jwt-decode';
import { environment } from '../../../environments/environment';
import { TimeRealService } from './time-real.service';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  refrescando: boolean = false;
  userToken: string;
  renewToken: string;
  private permisos = [];
  
  constructor(private http: HttpClient, private tr: TimeRealService) { }

  getEstadoRefrescando() {
    return this.refrescando;
  }

  setEstadoRefrescando(estado) {
    return this.refrescando=estado;
  }

  obtenerPermisos() {
    if (this.permisos.length < 1) {
      this.obtenerTipoUsuario();
    }

    return this.permisos;
  }

  obtenerTipoUsuario() {
    this.permisos = [];
    const token = localStorage.getItem('token');

    if (token) {
      const tokenD = decode(token);
      let roles = tokenD.rol

      roles.forEach(permiso => {
        this.permisos.push(permiso.id)
      });
    }

    return this.permisos;
  }

  login(validatingForm) {
    const authData = {
      email: validatingForm.value.modalFormDarkEmail,
      password: validatingForm.value.modalFormDarkPassword
    };//,app_version: 1.3

    return this.http.post(
      `${environment.apiUrl}/user/token/`,
      authData
    ).pipe(
      catchError(error => {
        if (error.status === 401) {
          console.error('Error en el login:', error.error);
          return throwError('Invalid email or password'); 
        } else if (error.status >= 500) {
          console.error('Internal server error:', error.error);
          return throwError('Server error. Please try again later.');
        } else {
          console.error('Unexpected error:', error);
          return throwError('An error occurred. Please try again later.');
        }
      }),
      map(resp => {
        const x = resp['access'];
        const y = resp['refresh'];

        this.guardarToken(x, y);
        return resp;
      })
    );
  }

  verificarTiempoToken() {
    var token = localStorage.getItem('token');
    if(token != undefined) {
      var tokenD = decode(token);
      var d = new Date(0);
      var actual = new Date();
      var caducidad = new Date(d.setUTCSeconds(tokenD.exp));
      var diferenciaMinutos = ((caducidad.getTime() - actual.getTime()) / 1000) / 60;
      if (diferenciaMinutos <= 5 && this.refrescando == false) {
        this.refrescando=true;

        this.refresh()
        .subscribe(resp => {
          this.refrescando = false;
          window.location.reload();
        });
      }
    }
  }

  refresh() {
    const refresh = localStorage.getItem('refresh');
    var authData = { refresh: refresh };

    return this.http.post(
      `${environment.apiUrl}/user/token/refresh/`,
      authData
    ).pipe(
      map(resp => {
        const access = resp['access'];
        localStorage.setItem('token', access);
        return resp;
      })
    );
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');

    this.tr.deleteSubscribe();
  }

  private guardarToken(access: string, refresh: string) {
    this.userToken = access;

    localStorage.setItem('token', access);
    localStorage.setItem('refresh', refresh);
  }
  
  isAuthenticated(): boolean {
    const token = localStorage.getItem('token');

    if (token) {
      return true;
    }
    return false;
  }
}
