import { Component, Inject } from '@angular/core';
import { User } from './shared/models/user';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'Application test';
  isLoggin:boolean = false;
  currentUser: User;

  constructor(
      private router: Router
  ) {
  }

  isAuthenticated() {
    if(localStorage.getItem('token')) {
      this.isLoggin = true;
      return this.isLoggin;
    }
    this.isLoggin = false;
    return this.isLoggin;
  }
  
  ngDoCheck() {
    this.isAuthenticated();
  }
}
