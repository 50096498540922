// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,

  // apiUrl: 'https://20bb-177-232-83-123.ngrok-free.app',
  // apiRT: 'wss://20bb-177-232-83-123.ngrok-free.app',
  apiUrl: 'https://schettinobackendsys.integriapp.com',
  apiRT:'wss://schettinobackendsys.integriapp.com'
};