import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { map} from 'rxjs/operators';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';

import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class TimeRealService {
  key: string;
  private url: string;
  public messages: Observable<any>;
  ws: WebSocketSubject<any>;

  constructor() {
  }

  deleteSubscribe() {
    this.ws.complete();
  }

  getData() {
    this.key = localStorage.getItem('token');
    this.url = environment.apiRT+"/camiones/ws/?token="+this.key;
    this.ws = webSocket(this.url);

    return this.ws.pipe(
      map(resp => {
        return resp;
      })
    );
  }
}
