import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/helpers/auth.guard';


const routes: Routes = [
  { path: '', loadChildren: () => import('./application/charge/charge.module').then(m=>m.ChargeModule) ,canActivate: [AuthGuard]},
  { path: 'auth', loadChildren: () => import('./public/public.module').then(m=>m.PublicModule)},
  { path: 'usuarios', loadChildren: () => import('./application/users/users.module').then(m=>m.UsersModule),canActivate: [AuthGuard]},
  { path: 'analisis', loadChildren: () => import('./application/planning/planning.module').then(m=>m.PlanningModule),canActivate: [AuthGuard]},
  { path: 'desarrollo', loadChildren: () => import('./application/developing/developing.module').then(m=>m.DevelopingModule),canActivate: [AuthGuard]},
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
