import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import decode from 'jwt-decode';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(public auth: AuthService,
    public router: Router) {
  }

  private permisos = [];
  
  canActivate(route: ActivatedRouteSnapshot): boolean {

    this.permisos = [];
    const data = route.data;
    this.permisos = this.auth.obtenerTipoUsuario();
    for (let i in data) {
      var item = data[i];
      if (this.permisos.find(permiso => permiso === item) != undefined) {
        return true;
      }
    }
    this.router.navigateByUrl('/');
    return false;
  }
  
  obtenerPermisos() {
    this.permisos = [];

    const token = localStorage.getItem('token');

    if (token) {
      const tokenD = decode(token);
      let roles = tokenD.rol;

      roles.forEach(element => {
        this.permisos.push(element.val)
      });
    }
    
    return this.permisos;
  }
}
