import { Injectable } from '@angular/core';
import { Pagina } from '../models/pagina';
import { Paginas } from '../models/paginas';

@Injectable({
  providedIn: 'root'
})

export class PaginasService {
  constructor() { }

  getPaginas(): Pagina[] {
    return Paginas;
  }
}
