import { Pagina } from './pagina';

export const Paginas: Pagina[] = [
  {
    rol: [1, 2, 4],
    nombre: 'Monitoreo',
    path: "",
    type: "M"
  },
  {
    rol: [1],
    nombre: 'Consulta sap',
    path: "/sap",
    type: "R"
  },
  {
    rol: [1],
    nombre: 'Análisis de procesos',
    path: "/analisis/procesos",
    type: "R"
  },
  {
    rol: [1],
    nombre: 'Ciclo total semanal',
    path: "/resumen-semanal",
    type: "R"
  },
  {
    rol: [1],
    nombre: 'Análisis del ciclo',
    path: "/resumen-mensual",
    type: "R"
  },
  {
    rol: [1],
    nombre: 'Análisis por proceso',
    path: "/resumen-semanal-proceso",
    type: "R"
  },
  {
    rol: [1],
    nombre: 'Análisis ejecutivo',
    path: "/resumen-ejecutivo",
    type: "R"
  },
  {
    rol: [1],
    nombre: 'Análisis de citas',
    path: "/resumen-citas",
    type: "R"
  },
  {
    rol: [1],
    nombre: 'Análisis de puntualidad',
    path: "/analisis",
    type: "R"
  },
  {
    rol: [1],
    nombre: 'Tiempo en planta',
    path: "/analisis/tiempo",
    type: "R"
  },

  {
    rol: [1],
    nombre: 'Usuarios',
    path: "/usuarios",
    type: "M"
  },
  {
    rol: [1],
    nombre: 'Tiempo de procesos',
    path: "/desarrollo/configurables",
    type: "C"
  },
  {
    rol: [1],
    nombre: 'Andenes',
    path: "/desarrollo/andenes",
    type: "C"
  },
  {
    rol: [1],
    nombre: 'Líneas transportistas',
    path: "/desarrollo/linea",
    type: "C"
  },

  {
    rol: [1, 5],
    nombre: 'Citas',
    path: "/citas",
    type: "M"
  },
  {
    rol: [1, 5],
    nombre: 'Reporte de unidades en planta',
    path: "/analisis/unidades",
    type: "M"
  },
];
