import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { RoleGuard } from '../../helpers/role.guard';
import { PaginasService } from '../../services/paginas.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {

  public permisos = [];
  paginas = [];
  paginasTempo;
  permiso;

  constructor(private auth: AuthService,
    private role: RoleGuard,
    private router: Router, private servicePaginas: PaginasService) { }

  ngOnInit() {
    this.getPaginas();
    this.getPermisos();
  }

  getPermisos() {
    this.paginas = [];
    this.permisos = this.auth.obtenerPermisos();

    for (let i in this.paginasTempo) {
      var item = this.paginasTempo[i];
      var roles = item["rol"];
      for (let j in roles) {
        var rolTempo = roles[j];
        if (this.permisos.find(permiso => permiso === rolTempo) != undefined) {
          let y= this.paginas.find(pagina => pagina.nombre === item["nombre"] );
          if (y== undefined) {
            this.paginas.push(item);
          }
        }
      }
    }
  }

  getPaginas(): void {
    this.paginasTempo = this.servicePaginas.getPaginas();
  }

  logOut() {
    this.auth.logout()
    this.router.navigateByUrl('/auth');
  };

}
